import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import { PageTitle } from "_metronic/layout/core";
import UsersPage from "./pages/users-list";
import { useIntl } from "react-intl";
import { lazy } from "react";
import ProfilePage from "../profile/ProfilePage";

const Registrations = lazy(() => import("./pages/pre-registrations"));
const UserModule = () => {
  const intl = useIntl();

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="users/active"
          element={
            <>
              <PageTitle>
                {intl.formatMessage({
                  id: "MENU.USER-MANAGEMENT.ACTIVE-USERS",
                })}
              </PageTitle>
              <UsersPage />
            </>
          }
        />

        <Route
          path="users/registrations"
          element={
            <>
              <PageTitle>
                {intl.formatMessage({
                  id: "PRE-RESERVATIONS",
                })}
              </PageTitle>
              <Registrations />
            </>
          }
        />
        <Route
          path="users/active/detail/:id/*"
          element={
            <>
              <ProfilePage />
            </>
          }
        >
          {/* <Route path="" element={<Navigate to="post" />} /> */}
        </Route>

        <Route index element={<Navigate to="/user-management/users" />} />
      </Route>
    </Routes>
  );
};

export default UserModule;
