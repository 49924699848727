import axios, { AxiosResponse } from "axios";
import {
  ContentsQueryResponse,
  MembersQueryResponse,
  TopContentReactionResponse,
  UsersQueryResponse,
  VideosQueryResponse,
} from "./_models";
import {
  ResponseAppDownloadsReport,
  ResponseLikeAndCommentReport,
  ResponseMultiUserActiveReport,
  ResponseRealTimeUserOnlineReport,
  ResponseVideoViewsReport,
} from "./QueryResponseProvider";
import moment from "moment";

const API_URL = process.env.REACT_APP_API_URL;
const GET_TOP_CONTENT_REACTION_URL = `${API_URL}/dashboard/top-referral`;

const getTopContentReaction = (
  query: any
): Promise<TopContentReactionResponse> => {
  const newQuery = { ...query };
  return axios
    .get(`${GET_TOP_CONTENT_REACTION_URL}`, { params: newQuery })
    .then((d: AxiosResponse<any>) => {
      return { list: d.data } as any;
    });
};

export { getTopContentReaction };
