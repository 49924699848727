import { Route, Routes, Outlet } from "react-router-dom";
import { PageTitle } from "_metronic/layout/core";
import { useIntl } from "react-intl";
import PurchaseHistoryList from "./pages/list-purchase-history";

const PurchaseHistoryPage = () => {
  const intl = useIntl();

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="list"
          element={
            <>
              <PageTitle>
                {intl.formatMessage({ id: "MENU.PURCHASE_HISTORY" })}
              </PageTitle>
              <PurchaseHistoryList />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default PurchaseHistoryPage;
