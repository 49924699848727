// @ts-nocheck
import { CircularProgress } from "@mui/material";
import { KTIcon, toAbsoluteUrl } from "_metronic/helpers";
import { Popconfirm, Select, Tag, Tooltip, notification } from "antd";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import { useQueryResponse } from "app/_components/coreListData/core/QueryResponseProvider";
import UserCellPoint from "app/_components/point/UserCellPoint";
import DateTimeCell from "app/_components/table/cells/DateTimeCell";
import { UserCustomHeader } from "app/_components/table/columns/UserCustomHeader";
import { ACCOUNT_TYPE_UPDATE } from "app/constants";
import clsx from "clsx";
import { useState } from "react";
import { useIntl } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import { delete_admin } from "../core/_requests";
import dayjs from "dayjs";
import { IcNow } from "_metronic/assets/svgs/Now";
import moment from "moment";
import { GENDER } from "app/_modules/profile/ProfileHeader";

export const PLATFORM = {
  RIGHT_NOW: "Rightnow HCM",
};

const usersColumns: any = (hasPermissionDelete: boolean) => [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ID"
        useQueryRequest={useQueryRequest}
        className="min-w-150px"
      />
    ),
    id: "id",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return <div>{row.id}</div>;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="PLATFORM"
        useQueryRequest={useQueryRequest}
        className="min-w-150px"
      />
    ),
    id: "platform",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return <div>{PLATFORM?.[row.platform]}</div>;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="NICKNAME"
        useQueryRequest={useQueryRequest}
        className="min-w-150px"
      />
    ),
    id: "nickname",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return <div>{row.nickName || "-"}</div>;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="GENDER"
        className="min-w-150px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "gender",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return <div>{GENDER[row.gender] || "-"}</div>;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="PHONE"
        className="min-w-150px"
        useQueryRequest={useQueryRequest}
      />
    ),

    id: "phone",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;

      return (
        <div>
          {row.countryCode ? row.countryCode + " " + row.phoneNumber : "-"}
        </div>
      );
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="NOW"
        className="min-w-150px"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
      />
    ),
    id: "balance",
    accessor: "now",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return (
        <div
          className="d-flex align-items-center justify-content-center gap-1 px-4 py-1 border"
          style={{ borderRadius: "18px" }}
        >
          <IcNow className="h-15px" />
          <div className="text-gray-800 dw-bold d-block fs-7">
            {row?.wallet?.balance || 0}
          </div>
        </div>
      );
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="REGISTER-DATE"
        className="min-w-100px"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
        isForceDesc={true}
      />
    ),
    id: "createdAt",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const date = props?.row?.original.createdAt;
      let day = moment.utc(date).format("YYYY-MM-DD");
      let time = moment.utc(date).format("HH:mm:ss");
      return (
        <div className="d-flex flex-column align-items-start">
          <div className="text-gray-800 dw-bold d-block mb-1 fs-7">{day}</div>
          <div className="text-muted fw-semibold text-muted d-block fs-7">
            {time}
          </div>
        </div>
      );
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ACTION"
        useQueryRequest={useQueryRequest}
        isCenter={true}
      />
    ),
    id: "action",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      const location = useLocation();
      return (
        <div className="d-flex" style={{ gap: 5 }}>
          {hasPermissionDelete && (
            <DeleteComponent row={props?.row?.original} />
          )}

          <Tooltip placement="top" title="User detail">
            <Link
              to={`${location.pathname}/detail/${props.row.original.id}`}
              className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary "
            >
              <KTIcon iconName="arrow-right" className="fs-2" />
            </Link>
          </Tooltip>
        </div>
      );
    },
  },
];

function DeleteComponent({ row }) {
  const { refetch } = useQueryResponse();
  const intl = useIntl();
  const [isLoadingDeleteUser, setIsLoadingDeleteUser] = useState(false);

  const handleDeleteUser = async () => {
    setIsLoadingDeleteUser(true);
    const res = await delete_admin(row.id);
    setIsLoadingDeleteUser(false);
    if (res.status == 200) {
      notification.success({
        message: intl.formatMessage({ id: "DELETE.USER-SUCCESS" }),
      });
      refetch();
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Popconfirm
        title={intl.formatMessage({ id: "DELETE" })}
        description={intl.formatMessage({ id: "USER.DELETE.CONFIRM" })}
        onConfirm={handleDeleteUser}
        onCancel={() => {}}
        okText={intl.formatMessage({ id: "YES" })}
        cancelText={intl.formatMessage({ id: "NO" })}
      >
        <button className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary">
          {isLoadingDeleteUser ? (
            <CircularProgress size={16} />
          ) : (
            <KTIcon iconName="trash" className="fs-2" />
          )}
        </button>
      </Popconfirm>
    </div>
  );
}

export { usersColumns };
