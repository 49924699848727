/* eslint-disable jsx-a11y/anchor-is-valid */
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Box, Tooltip } from "@mui/material";
import {
  QUERIES,
  isEnableTooltip,
  subString,
  toAbsoluteUrl,
} from "_metronic/helpers";
import EmptyChart from "_metronic/layout/components/empty/EmptyChart";
import Fancybox from "app/_components/Fancybox";
import Draggable from "app/_components/table/Draggable";
import ToolTipRanking from "app/_components/tooltips/ToolTipRanking";
import React, { useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useQuery } from "react-query";
import { getTopContentReaction } from "./core/_requests";
import TooltipWrapper from "app/_components/TooltipWrapper";
import { useQueryRequest } from "./core/QueryRequestProvider";
import { useQueryResponse } from "./core/QueryResponseProvider";
type Props = {
  className: string;
};

const TopRecommender: React.FC<Props> = ({ className }) => {
  const intl = useIntl();

  const tableRef = useRef<any>(null);

  const [type, setType] = React.useState("date");
  const [sortParams, setSortParams] = React.useState({
    key: "like_count",
    sortBy: "DESC",
  });

  const { queryFilterChart } = useQueryResponse();
  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    [`${QUERIES.TOP_CONTENT_REACTION}`, queryFilterChart],
    () => {
      return getTopContentReaction(queryFilterChart);
    },
    { cacheTime: 0, keepPreviousData: false, refetchOnWindowFocus: false }
  );

  let dataResult = response?.list?.statusCode === 403 ? [] : response?.list;
  const data = dataResult?.map((item: any, index: number) => ({
    ...item,
    img_rank: `/media/ranking/${index + 1}.svg`,
  }));

  const updateSort = (key: string) => {
    setSortParams((prev) => {
      const newParam = {
        sortBy: prev.sortBy === "DESC" ? "ASC" : "DESC",
        key: key,
      };

      return {
        ...prev,
        ...newParam,
      };
    });
  };

  React.useEffect(() => {
    refetch();
  }, [type, sortParams]);
  return (
    <div className={`card ${className} h-375px overflow-hidden`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1 d-flex">
            {intl.formatMessage({ id: "OVERVIEW.USER_STATUS.TOP_REFERRAL" })}
            {/* <ToolTipRanking /> */}
          </span>

          <span className="text-muted fw-semibold fs-7">
            {intl.formatMessage({
              id: "OVERVIEW.USER_STATUS.NUMBER_TOP_REFERRAL",
            })}
          </span>
        </h3>
      </div>
      {/* begin::Body */}{" "}
      <div className="card-body py-3">
        {/* begin::Table container */}
        <div
          ref={tableRef}
          className="table-responsive relative max-h-375px h-375px overflow-auto"
        >
          <Draggable>
            {/* begin::Table */}
            <div>
              <table className="table align-middle gs-0 gy-4">
                {data && data.length > 0 && (
                  <thead>
                    <tr className="fw-bold text-muted bg-light">
                      <th className="ps-4 min-w-150px align-items-center rounded-start d-flex">
                        {intl.formatMessage({ id: "RECOMMENDER" })}
                      </th>
                      <th
                        className="min-w-75px text-center cursor-pointer text-capitalize"
                        onClick={() => updateSort("REFERRED")}
                      >
                        {intl.formatMessage({ id: "REFERRED" })}
                      </th>
                    </tr>
                  </thead>
                )}

                <tbody>
                  {isFetching && (
                    <>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center placeholder-glow">
                            <div className="me-5">
                              <span
                                style={{ marginRight: 24 }}
                                className="placeholder bg-secondary rounded w-30px h-30px"
                              ></span>
                              <span className="placeholder bg-secondary rounded min-w-70px h-30px"></span>
                            </div>
                          </div>
                        </td>
                        {/* <td className="text-center">
                          <span className="placeholder bg-secondary  rounded col-1 w-10px h-30px"></span>
                        </td> */}
                        <td className="text-center">
                          <span className="placeholder bg-secondary rounded col-1 w-15px h-30px"></span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center placeholder-glow">
                            <div className="me-5">
                              <span
                                style={{ marginRight: 24 }}
                                className="placeholder bg-secondary rounded w-30px h-30px"
                              ></span>
                              <span className="placeholder bg-secondary rounded min-w-70px h-30px"></span>
                            </div>
                          </div>
                        </td>
                        {/* <td className="text-center">
                          <span className="placeholder bg-secondary  rounded col-1 w-10px h-30px"></span>
                        </td> */}
                        <td className="text-center">
                          <span className="placeholder bg-secondary rounded col-1 w-15px h-30px"></span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center placeholder-glow">
                            <div className="me-5">
                              <span
                                style={{ marginRight: 24 }}
                                className="placeholder bg-secondary rounded w-30px h-30px"
                              ></span>
                              <span className="placeholder bg-secondary rounded min-w-70px h-30px"></span>
                            </div>
                          </div>
                        </td>
                        {/* <td className="text-center">
                          <span className="placeholder bg-secondary  rounded col-1 w-10px h-30px"></span>
                        </td> */}
                        <td className="text-center">
                          <span className="placeholder bg-secondary rounded col-1 w-15px h-30px"></span>
                        </td>
                      </tr>
                    </>
                  )}

                  {!isFetching &&
                    data &&
                    data.map(
                      (item: any, index: React.Key | null | undefined) => (
                        <tr key={index}>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="me-5">
                                <img
                                  src={toAbsoluteUrl(item.img_rank)}
                                  className="h-75 align-self-end"
                                  alt=""
                                />
                              </div>
                              <div className="d-flex justify-content-start flex-column">
                                <TooltipWrapper
                                  text={item.description}
                                  limit={20}
                                >
                                  <span className="text-dark fw-bold mb-1 fs-6">
                                    {subString(item.customerName, 20)}
                                  </span>
                                </TooltipWrapper>

                                {/* </Tooltip> */}
                                <span className="text-muted fw-semibold text-muted d-block fs-7">
                                  {item.customerPhone}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="text-center text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                              {item?.referredCount}
                            </span>
                          </td>
                        </tr>
                      )
                    )}
                </tbody>

                {/* end::Table body */}
              </table>

              {!isFetching && data && data.length <= 0 ? (
                <div className="card-body py-3 w-100 min-h-400px d-flex align-items-center">
                  <EmptyChart isHiddenIcon />
                </div>
              ) : null}
            </div>
            {/* end::Table */}
          </Draggable>
        </div>

        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
};

export default TopRecommender;
