import { localEndDateToISO, localStartDateToISO } from "app/utils/apiUntils";
import axios from "axios";
const API_ENDPOINT = process.env.REACT_APP_API_URL;

export function getReservationUsers(start_date: any, end_date: any) {
  const body = {
    start_date: start_date,
    end_date: end_date,
    current_date: new Date(),
  };

  return axios.get(`${API_ENDPOINT}/dashboard/register`, {
    params: body,
  });
}

export function getClickCount(start_date: any, end_date: any) {
  const body = {
    start_date: start_date,
    end_date: end_date,
    current_date: new Date(),
  };

  return axios.get(`${API_ENDPOINT}/dashboard/referral-link-clicked-report`, {
    params: body,
  });
}

export function ABTestingChart(start_date: any, end_date: any) {
  const body = {
    start_date: start_date,
    end_date: end_date,
  };

  return axios.get(`${API_ENDPOINT}/dashboard/version`, {
    params: body,
  });
}
