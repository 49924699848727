/* eslint-disable react/jsx-no-target-blank */
import { useAuth } from "app/_modules/auth";
import { useIntl } from "react-intl";
import { PERMISSIONS_MAP } from "../../../../helpers";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";

const SidebarMenuMain = () => {
  const intl = useIntl();

  const { myPermission, allPermission } = useAuth();

  const hasPermission = (permissionName: any) => {
    const userPermission = myPermission.find(
      (perm: any) => perm.name === permissionName
    );

    const allPermissionArray = allPermission.find(
      (perm: any) => perm.name === permissionName
    );

    return (
      userPermission &&
      allPermissionArray &&
      // eslint-disable-next-line eqeqeq
      userPermission.id == allPermissionArray.id
    );
  };

  const canViewUserManagement =
    hasPermission(PERMISSIONS_MAP.USER_SUMMARY) ||
    hasPermission(PERMISSIONS_MAP.LIST_USER) ||
    hasPermission(PERMISSIONS_MAP.LIST_USER_DELETED) ||
    hasPermission(PERMISSIONS_MAP.LIST_USER_LOG);

  const canViewAdminManagement =
    hasPermission(PERMISSIONS_MAP.LIST_ADMIN) ||
    hasPermission(PERMISSIONS_MAP.LIST_ROLE) ||
    hasPermission(PERMISSIONS_MAP.LIST_ADMIN_LOG);

  const canViewContentManagement =
    hasPermission(PERMISSIONS_MAP.CONTENT_SUMMARY) ||
    hasPermission(PERMISSIONS_MAP.LIST_VIDEO) ||
    hasPermission(PERMISSIONS_MAP.LIST_POST) ||
    hasPermission(PERMISSIONS_MAP.LIST_SHARE) ||
    hasPermission(PERMISSIONS_MAP.LIST_HASHTAG);

  const canViewAlarmManagement =
    hasPermission(PERMISSIONS_MAP.LIST_PUSH) ||
    hasPermission(PERMISSIONS_MAP.LIST_EVENT) ||
    hasPermission(PERMISSIONS_MAP.LIST_NOTICE);

  const canViewSettingManagement =
    hasPermission(PERMISSIONS_MAP.LIST_AD_SETTING) ||
    hasPermission(PERMISSIONS_MAP.LIST_APP_SETTING) ||
    hasPermission(PERMISSIONS_MAP.LIST_RANKING_SETTING) ||
    hasPermission(PERMISSIONS_MAP.LIST_TERM) ||
    hasPermission(PERMISSIONS_MAP.LIST_LOCALIZATION_SETTING);

  return (
    <>
      <SidebarMenuItem
        to="/dashboard"
        icon="element-11"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        fontIcon="bi-app-indicator"
      />

      <SidebarMenuItem
        to="/agency-management/active"
        icon="user-tick"
        title={intl.formatMessage({ id: "MENU.AGENCY_MANAGEMENT" })}
        fontIcon="bi-layers"
      />
      {/*  <SidebarMenuItem
          to="/agency-management/active"
          title={intl.formatMessage({
            id: "MENU.ACTIVE_AGENCIES",
          })}
          hasBullet={true}
        /> */}
      {/* UserManagement */}
      <SidebarMenuItemWithSub
        to="/user-management"
        icon="profile-circle"
        title={intl.formatMessage({ id: "MENU.USER-MANAGEMENT" })}
        fontIcon="bi-layers"
      >
        <SidebarMenuItem
          to="/user-management/users/active"
          title={intl.formatMessage({
            id: "MENU.USER-MANAGEMENT.ACTIVE-USERS",
          })}
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/user-management/users/registrations"
          title={intl.formatMessage({
            id: "PRE-RESERVATIONS",
          })}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        to="/product-management/list"
        icon="parcel"
        title={intl.formatMessage({ id: "PRODUCT" })}
        fontIcon="bi-layers"
      />
      {/* <SidebarMenuItem
          to="/product-management/list"
          title={intl.formatMessage({
            id: "PRODUCT.LIST",
          })}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub> */}

      <SidebarMenuItem
        to="/purchase-management/list"
        icon="bill"
        title={intl.formatMessage({ id: "PURCHASE" })}
        fontIcon="bi-layers"
      />
      {/* <SidebarMenuItem
          to="/purchase-management/list"
          title={intl.formatMessage({
            id: "PURCHASE.LIST",
          })}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub> */}

      <SidebarMenuItem
        to="/ab-testing"
        icon="test-tubes"
        title={intl.formatMessage({ id: "MENU.EXPERIMENT_SEGMENT" })}
        fontIcon="bi-app-indicator"
      />
    </>
  );
};

export { SidebarMenuMain };
