/* eslint-disable jsx-a11y/anchor-is-valid */
import { FormControl } from "@mui/material";
import { useLang } from "_metronic/i18n/Metronici18n";
import { ColorPicker, DatePicker, Select, notification } from "antd";
import dayjs from "dayjs";
import { useIntl } from "react-intl";
import en from "antd/es/date-picker/locale/en_US";
import ko from "antd/es/date-picker/locale/ko_KR";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputComponent from "./InputComponent";
import { create_product, edit_product } from "../../core/_requests";

const OPTION_PAYMENT_PLATFORM = [
  {
    label: "Google",
    value: "google",
  },
  {
    label: "Apple",
    value: "apple",
  },
];

const Step1 = ({ data, updateData, refetch, handleClose, info }: any) => {
  const isEdit = !!info;
  const intl = useIntl();
  const lang = useLang();

  const createEditProductSchema = Yup.object().shape({
    platform: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: "REQUIRED" })),
    credit: Yup.number()
      .typeError(intl.formatMessage({ id: "REQUIRED" }))
      .required(intl.formatMessage({ id: "REQUIRED" })),
    code: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: "REQUIRED" })),
    name_ko: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: "REQUIRED" })),

    label_ko: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: "REQUIRED" })),

    caption_ko: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: "REQUIRED" })),

    priceList_ko: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: "REQUIRED" })),

    priceDiscount_ko: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: "REQUIRED" })),

    name_en: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: "REQUIRED" })),

    label_en: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: "REQUIRED" })),

    caption_en: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: "REQUIRED" })),

    priceList_en: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: "REQUIRED" })),

    priceDiscount_en: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: "REQUIRED" })),

    name_vi: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: "REQUIRED" })),

    label_vi: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: "REQUIRED" })),

    caption_vi: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: "REQUIRED" })),

    priceList_vi: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: "REQUIRED" })),

    priceDiscount_vi: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: "REQUIRED" })),
  });

  const initialValues = {
    platform: data?.platform || OPTION_PAYMENT_PLATFORM[0]?.value,
    credit: data?.credit || "",
    code: data?.code || "",

    primaryColor: data?.primaryColor || "#D3ECFE",
    secondaryColor: data?.secondaryColor || "#D3D8FE",

    releaseDate: dayjs(data?.releaseDate) || dayjs(),

    name_ko: data?.name?.ko || "",
    label_ko: data?.label?.ko || "",
    caption_ko: data?.caption?.ko || "",
    priceList_ko: data?.priceList?.ko?.price || 0,
    priceDiscount_ko: data?.priceList?.ko?.priceDiscount || 0,

    name_en: data?.name?.en || "",
    label_en: data?.label?.en || "",
    caption_en: data?.caption?.en || "",
    priceList_en: data?.priceList?.en?.price || 0,
    priceDiscount_en: data?.priceList?.en?.priceDiscount || 0,

    name_vi: data?.name?.vi || "",
    label_vi: data?.label?.vi || "",
    caption_vi: data?.caption?.vi || "",
    priceList_vi: data?.priceList?.vi?.price || 0,
    priceDiscount_vi: data?.priceList?.vi?.priceDiscount || 0,
  };

  const formik: any = useFormik({
    enableReinitialize: isEdit,
    initialValues,
    validationSchema: createEditProductSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        const fortmat_data = {
          id: info?.id,
          // createdAt: "2024-01-15T01:08:31.708Z",
          // updatedAt: "2024-01-15T07:30:07.000Z",
          code: values?.code,
          name: {
            en: values?.name_en,
            ko: values?.name_ko,
            vi: values?.name_vi,
          },
          caption: {
            en: values?.caption_en,
            ko: values?.caption_ko,
            vi: values?.caption_vi,
          },
          platform: values?.platform,
          credit: values?.credit || 0,
          //image: null,
          primaryColor: values?.primaryColor,
          secondaryColor: values?.secondaryColor,
          label: {
            en: values?.label_en,
            ko: values?.label_ko,
            vi: values?.label_vi,
          },
          releaseDate: values?.releaseDate,
          //isActive: true,
          priceList: {
            en: {
              sign: "$",
              price: values?.priceList_en,
              currency: "USD",
              priceDiscount: values?.priceDiscount_en,
              discountPercent: 0,
            },
            ko: {
              sign: "₩",
              price: values?.priceList_ko,
              currency: "KRW",
              priceDiscount: values?.priceDiscount_ko,
              discountPercent: 0,
            },
            vi: {
              sign: "đ",
              price: values?.priceList_vi,
              currency: "VND",
              priceDiscount: values?.priceDiscount_vi,
              discountPercent: 0,
            },
          },
          purchaseCount: 0,
          refundCount: 0,
        };
        if (info) {
          await edit_product(info.id, fortmat_data);
        } else {
          await create_product(fortmat_data);
        }
        notification.success({
          message: info?.id
            ? intl.formatMessage({
                id: "UPDATE-SUCCESS",
              })
            : intl.formatMessage({
                id: "CREATE-SUCCESS",
              }),
        });
        handleClose();
        refetch();
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <form
      noValidate
      id="kt_modal_create_app_form"
      autoComplete="off"
      onSubmit={formik.handleSubmit}
      className="customizedForm"
    >
      <div className="current" data-kt-stepper-element="content">
        <div className="w-100">
          <div
            className="row"
            style={{ display: "flex", gap: 10, flexWrap: "wrap" }}
          >
            <div className="fv-row col mb-10">
              <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                <span className="required">
                  {intl.formatMessage({ id: "PLATFORM" })}
                </span>
              </label>
              <Select
                style={{ width: "100%", height: 44 }}
                onChange={(value) => formik.setFieldValue("platform", value)}
                placeholder={intl.formatMessage({ id: "SELECT-PLATFORM" })}
                value={formik?.values?.platform}
              >
                {OPTION_PAYMENT_PLATFORM?.map((option) => (
                  <Select.Option key={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>

              {formik.touched.platform && formik.errors.platform && (
                <div className="text-danger mt-2">
                  {formik?.errors?.platform}
                </div>
              )}
            </div>

            <InputComponent
              formik={formik}
              label={intl.formatMessage({ id: "RIGHTNOW-NOW" })}
              fieldKey="credit"
              inputType="number"
            />
          </div>
          <div className="row" style={{ display: "flex", gap: 10 }}>
            <InputComponent
              formik={formik}
              label={intl.formatMessage({ id: "CODE" })}
              fieldKey="code"
            />
            <div className="fv-row col mb-10">
              <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                <span className="required">
                  {intl.formatMessage({ id: "RELEASE_DATE" })}
                </span>
              </label>
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  backgroundColor: "#f9f9f9",
                  border: "none",
                  borderRadius: "0.625rem",
                  fieldset: {
                    border: "none",
                  },
                  ".MuiInputBase-root": {
                    backgroundColor: "#f9f9f9",
                    border: "none",
                  },
                }}
              >
                <DatePicker
                  locale={lang === "ko" ? ko : en}
                  format={"YYYY-MM-DD HH:mm"}
                  // value={data?.releaseDate ? dayjs(data?.releaseDate) : null}
                  showTime={{ format: "HH:mm" }}
                  onChange={(data, date) => {
                    formik.setFieldValue("releaseDate", date);
                  }}
                  value={formik?.values?.releaseDate}
                />
              </FormControl>

              {formik.touched.releaseDate && formik.errors.releaseDate && (
                <div className="text-danger mt-2">
                  {formik?.errors?.releaseDate}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 mb-10 d-flex align-items-end justify-content-between gap-4">
              <ColorPicker
                className="justify-content-start ps-3"
                style={{ height: 44, width: "50%" }}
                showText={() => <span>Primary</span>}
                value={formik?.values?.primaryColor}
                onChangeComplete={(e) => {
                  updateData((prev: any) => ({
                    ...prev,
                    primaryColor: e.toHexString(),
                  }));
                }}
              />
              <ColorPicker
                className="justify-content-start ps-3"
                style={{ height: 44, width: "50%" }}
                showText={() => <span>Secondary</span>}
                value={formik?.values?.secondaryColor}
                onChangeComplete={(e) => {
                  updateData((prev: any) => ({
                    ...prev,
                    secondaryColor: e.toHexString(),
                  }));
                }}
              />
            </div>
          </div>

          {/* KO */}
          <div>
            <label className="d-flex align-items-center fs-4 fw-bold mb-2">
              {intl.formatMessage({ id: "KOREAN" })}
            </label>
            <div className="row">
              <InputComponent
                formik={formik}
                label={intl.formatMessage({ id: "NAME" }) + " (KO)"}
                fieldKey="name_ko"
              />

              <InputComponent
                formik={formik}
                label={intl.formatMessage({ id: "LABEL" }) + " (KO)"}
                fieldKey="label_ko"
              />
            </div>
            <div className="row">
              <InputComponent
                formik={formik}
                label={intl.formatMessage({ id: "CAPTION" }) + " (KO)"}
                fieldKey="caption_ko"
              />

              <div className="col">
                <div className="row">
                  <InputComponent
                    formik={formik}
                    label={intl.formatMessage({ id: "PRICE" })}
                    fieldKey="priceList_ko"
                    inputType="number"
                  />
                  <InputComponent
                    formik={formik}
                    label={intl.formatMessage({ id: "DISCOUNT" })}
                    fieldKey="priceDiscount_ko"
                    inputType="number"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* EN */}
          <div>
            <label className="d-flex align-items-center fs-4 fw-bold mb-2">
              {intl.formatMessage({ id: "ENGLISH" })}
            </label>
            <div className="row">
              <InputComponent
                formik={formik}
                label={intl.formatMessage({ id: "NAME" }) + " (EN)"}
                fieldKey="name_en"
              />

              <InputComponent
                formik={formik}
                label={intl.formatMessage({ id: "LABEL" }) + " (EN)"}
                fieldKey="label_en"
              />
            </div>
            <div className="row">
              <InputComponent
                formik={formik}
                label={intl.formatMessage({ id: "CAPTION" }) + " (EN)"}
                fieldKey="caption_en"
              />

              <div className="col">
                <div className="row">
                  <InputComponent
                    formik={formik}
                    label={intl.formatMessage({ id: "PRICE" })}
                    fieldKey="priceList_en"
                    inputType="number"
                  />

                  <InputComponent
                    formik={formik}
                    label={intl.formatMessage({ id: "DISCOUNT" })}
                    fieldKey="priceDiscount_en"
                    inputType="number"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* VI */}

          <div>
            <label className="d-flex align-items-center fs-4 fw-bold mb-2">
              {intl.formatMessage({ id: "VIETNAMESE" })}
            </label>
            <div className="row">
              <InputComponent
                formik={formik}
                label={intl.formatMessage({ id: "NAME" }) + " (VI)"}
                fieldKey="name_vi"
              />

              <InputComponent
                formik={formik}
                label={intl.formatMessage({ id: "LABEL" }) + " (VI)"}
                fieldKey="label_vi"
              />
            </div>
            <div className="row">
              <InputComponent
                formik={formik}
                label={intl.formatMessage({ id: "CAPTION" }) + " (VI)"}
                fieldKey="caption_vi"
              />

              <div className="col">
                <div className="row">
                  <InputComponent
                    formik={formik}
                    label={intl.formatMessage({ id: "PRICE" })}
                    fieldKey="priceList_vi"
                    inputType="number"
                  />

                  <InputComponent
                    formik={formik}
                    label={intl.formatMessage({ id: "DISCOUNT" })}
                    fieldKey="priceDiscount_vi"
                    inputType="number"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-stack justify-content-end">
        <button
          disabled={formik.isSubmitting || !formik.isValid}
          className="btn btn-lg btn-primary"
          //data-kt-stepper-action="next"
          //onClick={handleSubmit}
          type="submit"
        >
          {intl.formatMessage({ id: "SAVE" })}
        </button>
      </div>{" "}
    </form>
  );
};

export { Step1 };
