import {
  KTCard,
  OPTION_KEYS_HEADER,
  PERMISSIONS_MAP,
  hasPermission,
} from "_metronic/helpers";
import TableSummary from "app/_components/TableSummary";
import { UsersListHeader } from "app/_components/coreListData/header/ListHeader";
import { Table } from "app/_components/table/Table";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { usersColumns } from "./table/_columns";

import TableWrapper from "app/_components/coreListData";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "app/_components/coreListData/core/QueryResponseProvider";
import { useAuth } from "app/_modules/auth";
import { getPanigationUsers } from "./core/_requests";

interface Props {
  className?: string;
  data?: any;
  pagination?: any;
  type?: string;
  columns?: any;
}
export const UsersList = ({ data, pagination, type, columns }: Props) => {
  const users: any = useQueryResponseData();
  const dataUser = useMemo(() => users, [users]);

  const { allPermission, myPermission } = useAuth();

  const intl = useIntl();

  const pag = useQueryResponsePagination();

  const res: any = useQueryResponse();

  const user_platform = res?.response?.user_platform;

  let cardList = [
    {
      title: intl.formatMessage({
        id: type === "comment_count" ? "COMMENT" : "USER",
      }),
      value: pagination?.total || pag?.total,
    },
  ];

  if (user_platform?.length) {
    cardList = [
      ...cardList,
      {
        title: intl.formatMessage({
          id: "PLATFORM-GOOGLE",
        }),
        value:
          user_platform?.find((i: any) => i.platform === "google")?.total ||
          "0",
      },

      {
        title: intl.formatMessage({
          id: "PLATFORM-FACEBOOK",
        }),
        value:
          user_platform?.find((i: any) => i.platform === "facebook")?.total ||
          "0",
      },

      {
        title: intl.formatMessage({
          id: "PLATFORM-WISHNOTE",
        }),
        value:
          user_platform?.find((i: any) => i.platform === "wishnote")?.total ||
          "0",
      },

      {
        title: intl.formatMessage({
          id: "PLATFORM-KAKAO",
        }),
        value:
          user_platform?.find((i: any) => i.platform === "kakao")?.total || "0",
      },

      {
        title: intl.formatMessage({
          id: "PLATFORM-NAVER",
        }),
        value:
          user_platform?.find((i: any) => i.platform === "naver")?.total || "0",
      },

      {
        title: intl.formatMessage({
          id: "PLATFORM-AMAZON",
        }),
        value:
          user_platform?.find((i: any) => i.platform === "amazon")?.total ||
          "0",
      },

      {
        title: intl.formatMessage({
          id: "PLATFORM-APPLE",
        }),
        value:
          user_platform?.find((i: any) => i.platform === "apple")?.total || "0",
      },
    ];
  }

  cardList.sort((a, b) => {
    return b.value - a.value;
  });

  const hasPermissionDelete = hasPermission(
    PERMISSIONS_MAP.REMOVE_USER,
    allPermission,
    myPermission
  );

  let permissionColumns = useMemo(
    () => usersColumns(hasPermissionDelete),
    [allPermission, myPermission]
  );
  const columnUse = useMemo(() => {
    return type === "comment_count" ? columns : permissionColumns;
  }, [permissionColumns]);

  return (
    <>
      <UsersListHeader optionsHeader={OPTION_KEYS_HEADER.USER_ACTIVE} />
      <KTCard>
        <TableSummary cardList={cardList} />
        <Table
          defineColumns={columnUse}
          data={data || dataUser}
          useQueryResponsePagination={useQueryResponsePagination}
          useQueryResponseLoading={useQueryResponseLoading}
          useQueryRequest={useQueryRequest}
          pagination={pagination}
          // className="frenzy"
        />
      </KTCard>
    </>
  );
};

const UsersListWrapper = () => (
  <TableWrapper
    getPanigation={getPanigationUsers}
    children={<UsersList />}
  ></TableWrapper>
);

export default UsersListWrapper;
