// @ts-nocheck
import { KTIcon, toAbsoluteUrl } from "_metronic/helpers";
import { Popconfirm } from "antd";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { CircularProgress } from "@mui/material";
import { notification } from "antd";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import { useQueryResponse } from "app/_components/coreListData/core/QueryResponseProvider";
import { UserCustomHeader } from "app/_components/table/columns/UserCustomHeader";
import { useState } from "react";
import { CreateAppModal } from "../components/edit-product";
import { delete_product } from "../core/_requests";
import DateTimeCell from "app/_components/table/cells/DateTimeCell";
import dayjs from "dayjs";
import { useLang } from "_metronic/i18n/Metronici18n";

const usersColumns: any = (
  hasPermissionDelete = true,
  hasPermissionUpdate = true
) => [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="PLATFORM"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "platform",
    accessor: "platform",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;

      let imageSrc;
      switch (row?.platform) {
        case "apple":
          imageSrc = toAbsoluteUrl("/media/social_media/apple.svg");
          break;

        case "google":
          imageSrc = toAbsoluteUrl("/media/social_media/google.svg");
          break;
      }

      return (
        <div className={`d-flex align-items-center`}>
          <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
            <div className={clsx("symbol-label fs-3")}>
              <img src={imageSrc} className="w-50" />
            </div>
          </div>
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="NAME"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "name",
    accessor: "name",
    Cell: ({ ...props }) => {
      const lang = useLang();

      const row = props?.row?.original;
      if (lang == "ko")
        return (
          <>
            <div>{row.name?.vi}</div>
            <div>{row.name?.ko}</div>
          </>
        );
      else
        return (
          <>
            <div>{row.name?.vi}</div>
            <div>{row.name?.en}</div>
          </>
        );
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="PRICE_VI"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
        isCenter
      />
    ),
    isCenterRow: true,
    id: "price_vi",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      const { price, sign } = row?.priceList?.vi || {};

      return (
        <>
          {new Intl.NumberFormat().format(price) || "0"}
          {sign}
        </>
      );
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="PRICE_EN"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
        isCenter
      />
    ),
    id: "price_en",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      const { price, sign } = row?.priceList?.en || {};

      return (
        <>
          {sign}
          {new Intl.NumberFormat().format(price) || "0"}
        </>
      );
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="PRICE_KR"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
        isCenter
      />
    ),
    id: "price_kr",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      const { price, sign } = row?.priceList?.ko || {};

      return (
        <>
          {new Intl.NumberFormat().format(price) || "0"}
          {sign}
        </>
      );
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="NOW"
        isNoSort={false}
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
      />
    ),
    isCenterRow: true,
    id: "credit",
    accessor: "credit",
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="RELEASE_DATE"
        className="min-w-125px"
        isNoSort={false}
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "releaseDate",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const row = props.row.original;
      return (
        <DateTimeCell
          date={dayjs(row.releaseDate).format("YYYY-MM-DD HH:mm:ss")}
        />
      );
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="PURCHUSE_COUNT"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
      />
    ),
    id: "purchaseCount",
    isCenterRow: true,
    accessor: "purchaseCount",
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="REFUND_COUNT"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
      />
    ),
    id: "refundCount",
    isCenterRow: true,
    accessor: "refundCount",
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ACTION"
        useQueryRequest={useQueryRequest}
        isCenter={true}
      />
    ),
    id: "action",
    isCenterRow: true,
    Cell: ({ ...props }) => (
      <div className="d-flex text-end" style={{ gap: 5 }}>
        {hasPermissionUpdate && <EditProductModal row={props?.row?.original} />}
        {hasPermissionDelete && <DeleteComponent row={props?.row?.original} />}
      </div>
    ),
  },
];

function EditProductModal({ row }) {
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);

  const handleOnClose = () => {
    setShowCreateAppModal(false);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          type="button"
          className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary "
          onClick={() => setShowCreateAppModal(true)}
        >
          <KTIcon iconName="notepad-edit" className="fs-2" />
        </button>
      </div>

      {showCreateAppModal && (
        <CreateAppModal
          show={showCreateAppModal}
          handleClose={handleOnClose}
          info={row}
        />
      )}
    </>
  );
}

function DeleteComponent({ row }) {
  const { refetch } = useQueryResponse();
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const intl = useIntl();

  const handleDelete = async () => {
    setIsLoadingDelete(true);
    const res = await delete_product(row.id);
    setIsLoadingDelete(false);
    if (res.status == 200) {
      notification.success({
        message: intl.formatMessage({ id: "DELETE-SUCCESS" }),
      });
      refetch();
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Popconfirm
        title="Delete"
        description="Are you sure to delete this product?"
        onConfirm={handleDelete}
        onCancel={() => {}}
        okText="Yes"
        cancelText="No"
      >
        <button className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary">
          {isLoadingDelete ? (
            <CircularProgress size={16} />
          ) : (
            <KTIcon
              iconName="trash"
              className="fs-2 color-primary fill-primary"
            />
          )}
        </button>
      </Popconfirm>
    </div>
  );
}

export { usersColumns };
