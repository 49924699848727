/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLang } from "_metronic/i18n/Metronici18n";
import EmptyChart from "_metronic/layout/components/empty/EmptyChart";
import ApexCharts, { ApexOptions } from "apexcharts";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import {
  getCSS,
  getCSSVariableValue,
} from "../../../../_metronic/assets/ts/_utils";
import { useThemeMode } from "../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider";
import { useQueryResponse } from "./core/QueryResponseProvider";
import NumberDelay from "app/_components/NumberDelay";
import {
  ABTestingChart,
  getClickCount,
  getReservationUsers,
} from "../dashboard.service";
import { useQueryRequest } from "./core/QueryRequestProvider";

type Props = {
  className: string;
  isSummary: boolean;
};

const UserPreRegisteredByLinkChart: React.FC<Props> = ({
  className,
  isSummary,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();
  const [data, setData] = useState<any>();
  const { state } = useQueryRequest();
  const intl = useIntl();
  const lang = useLang();
  const [typeOfChart, setTypeOfChart] = React.useState("week");
  const { query } = useQueryResponse();
  const refreshMode = () => {
    if (!chartRef.current) {
      return;
    }

    const height = parseInt(getCSS(chartRef.current, "height"));

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(200, result, x, typeOfChart, lang, data)
    );
    if (chart) {
      chart.render();
    }

    return chart;
  };

  useEffect(() => {
    async function getData() {
      const res = await ABTestingChart(state?.start_date, state?.end_date);

      if (res?.status == 200) setData(res?.data);
    }

    getData();
  }, [state]);

  const AVersionData = {
    name: intl.formatMessage({ id: "A" }),
    data: data?.data?.map((item: any) => ({
      x: item?.date,
      y: Math.round((item?.createdA / (item?.generateA || 1)) * 100 || 0),
    })),
  };

  const BVersionData = {
    name: intl.formatMessage({ id: "B" }),
    data: data?.data?.map((item: any) => ({
      x: item?.date,
      y: Math.round((item?.createdB / (item?.generateB || 1)) * 100 || 0),
    })),
  };

  const x =
    typeOfChart === "week"
      ? data?.data?.map((obj: { date: string }) => {
          const dataItem = `${obj.date?.substring(5, 7)}/${obj.date?.substring(
            8,
            10
          )}/${obj.date?.substring(0, 4)}`;
          return dataItem || 0;
        })
      : data?.data?.map((obj: { date: string }) => {
          if (obj.date) {
            const hour = parseInt(obj.date.substring(11, 13));
            return hour + "h";
          }
          return 0 + "h";
        });

  const result = [AVersionData, BVersionData];

  // const values = Object.values(data);

  useEffect(() => {
    if (query) {
      if ((query as any)?.start_date === (query as any)?.end_date) {
        setTypeOfChart("day");
      } else {
        setTypeOfChart("week");
      }
    }
  }, [query]);

  useEffect(() => {
    let chart: any = null;
    if (data) {
      chart = refreshMode();
    }
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, mode, data, refreshMode]);

  // const lastItem = data[data?.length - 1];

  // if (!lastItem)
  //   return (
  //     <div className={`card ${className}`}>
  //       {/* begin::Beader */}
  //       <div className="card-header border-0 py-5">
  //         <h3 className="card-title align-items-start flex-column">
  //           <span className="card-label fw-bold fs-3 mb-1">
  //             {intl.formatMessage({ id: "OVERVIEW.VIDEOS.TITLE" })}
  //           </span>

  //           <span className="text-muted fw-semibold fs-7">
  //             {intl.formatMessage({ id: "OVERVIEW.VIDEOS.DESCRIPTION" })}
  //           </span>
  //         </h3>
  //       </div>
  //     </div>
  //   );

  return (
    <div className={`card ${className}`}>
      {/* begin::Beader */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">
            {intl.formatMessage({
              id: "AB_TESTING",
            })}
          </span>

          <span className="text-muted fw-semibold fs-7">
            {intl.formatMessage({
              id: "AB_TESTING_DESCRIPTION",
            })}
          </span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body p-0 d-flex flex-column">
        {/* begin::Stats */}
        <div
          className="card-p pt-5 flex-grow-1"
          style={{ backgroundColor: "rgb(238 247 255)", borderRadius: 10 }}
        >
          <div className="d-flex justify-content-around">
            <div className="text-center">
              <div className=" text-muted fw-semibold">
                {intl.formatMessage({ id: "A" })}
              </div>
              {/* end::Label */}
              {/* begin::Stat */}
              <div>
                <div
                  className="fs-4 fw-bold"
                  style={{ color: "rgb(0,67,157)" }}
                >
                  <NumberDelay
                    number={Math.round(
                      (data?.countCreatedA /
                        (Number(data?.countGenerateA) || 1)) *
                        100 || 0
                    )}
                  />
                  %
                </div>
                <div
                  style={{
                    color: "rgb(0,67,157)",
                    fontSize: 10,
                    fontWeight: 600,
                  }}
                >
                  (
                  {`${data?.countCreatedA || "-"}/${
                    data?.countGenerateA || "-"
                  }`}
                  )
                </div>
              </div>
            </div>
            <div>
              <div className="text-center">
                <div className=" text-muted fw-semibold">
                  {intl.formatMessage({ id: "B" })}
                </div>
                {/* end::Label */}
                {/* begin::Stat */}
                <div>
                  <div className="fs-4 fw-bold" style={{ color: "black" }}>
                    <NumberDelay
                      number={Math.round(
                        (data?.countCreatedB /
                          (Number(data?.countGenerateB) || 1)) *
                          100 || 0
                      )}
                    />
                    %
                    <div
                      style={{ color: "black", fontSize: 10, fontWeight: 600 }}
                    >
                      (
                      {`${data?.countCreatedB || "-"}/${
                        data?.countGenerateB || "-"
                      }`}
                      )
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end::Row */}
        </div>
        {/* end::Stats */}

        {/* begin::Chart */}
        <div className="w-100 h-200px">
          {/* {!isLoadingLikeAndCommentChart && resultData?.length > 0 ? ( */}
          <div>
            {/* begin::Chart */}
            <div
              ref={chartRef}
              className={`mixed-widget-3-chart card-rounded-bottom ${"h-150px"}`}
              // data-kt-chart-color={chartColor}
              // style={{ height: chartHeight }}
            ></div>
          </div>
        </div>

        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  );
};

const ABTestingChartWrapper = ({ className, isSummary = false }: any) => (
  <UserPreRegisteredByLinkChart isSummary={isSummary} className={className} />
);

export { ABTestingChartWrapper };

function getChartOptions(
  height: number,
  data: any,
  x: any,
  typeOfChart: string,
  lang: string,
  rowData: any
): ApexOptions {
  const color2 = "#009ef7";
  const color1 = getCSSVariableValue("--bs-warning");
  const labelColor = getCSSVariableValue("--bs-gray-500");
  const borderColor = getCSSVariableValue("--bs-gray-200");

  const baseLightColor = getCSSVariableValue("--bs-success-light");
  const secondaryLightColor = getCSSVariableValue("--bs-warning-light");
  return {
    series: data,
    fill: {
      type: "gradient",
    },
    chart: {
      fontFamily: "inherit",
      type: "bar",
      height: height,
      toolbar: {
        show: false,
        tools: {
          download: true,
          zoom: false,
          reset: false,
          selection: false,
          zoomin: false,
          zoomout: false,
          pan: false,
        },
      },
      // background: "linear-gradient(195deg, #49a3f1, #1A73E8)",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        columnWidth:
          data?.data?.length > 10
            ? "80%"
            : data?.data?.length > 7
            ? "60%"
            : "30%",
      },
    },

    grid: {
      show: true,
      borderColor: borderColor,
      strokeDashArray: 4,
    },
    responsive: [
      {
        breakpoint: 1280,
        options: {
          chart: {
            height: 200,
          },
        },
      },
    ],

    stroke: {
      curve: "smooth",
      show: true,
      width: 2,
    },
    xaxis: {
      tickAmount: 3,
      labels: {
        show: true,
        style: {
          fontSize: "12px",
          colors: labelColor,
        },
        formatter(value) {
          return typeOfChart === "week"
            ? new Date(value).toLocaleDateString(
                lang === "en" ? "en-us" : "ko-KR",
                {
                  month: "short",
                  day: "2-digit",
                }
              )
            : value;
        },
        rotate: 0,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
        formatter: function (val) {
          return val?.toFixed(0);
        },
      },
      crosshairs: {
        show: false,
      },
      decimalsInFloat: 0,
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      y: {
        formatter: function (val: any, all: any) {
          const thisLineData = rowData?.data;
          const thisPointData = thisLineData[all?.dataPointIndex];

          if (all?.seriesIndex == 0)
            return `${val}% (${thisPointData?.createdA}/${thisPointData?.generateA})`;

          if (all?.seriesIndex == 1)
            return `${val}% (${thisPointData?.createdB}/${thisPointData?.generateB})`;

          return val + "%";
        },
      },
      marker: {
        show: true,
      },
    },

    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },

    markers: {
      colors: ["rgb(0, 143, 251)", baseLightColor, secondaryLightColor],
      strokeColors: [secondaryLightColor, baseLightColor],
      strokeWidth: 3,
    },

    colors: ["rgb(0,67,157)", "rgb(7, 172, 255)"],
  };
}
