import Dashboard from "app/_modules/dashboard";
import UserPage from "app/_modules/user-management";
import { FC, Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";

import { QueryRequestProvider } from "app/_modules/dashboard/components/core/QueryRequestProvider";
import { QueryResponseProvider } from "app/_modules/dashboard/components/core/QueryResponseProvider";
import ProductPage from "app/_modules/product-management";
import PurchaseHistoryPage from "app/_modules/purchase-history";

const PrivateRoutes = () => {
  const AgencyManagementActive = lazy(
    () => import("app/_modules/agencyManagement")
  );
  const UserManagementModule = lazy(
    () => import("app/_modules/user-management")
  );

  const ExperimentSegment = lazy(
    () => import("app/_modules/experiment-segment")
  );
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to="/" />} />
        <Route
          path="dashboard/*"
          element={
            <QueryRequestProvider>
              <QueryResponseProvider>
                <Dashboard />
              </QueryResponseProvider>
            </QueryRequestProvider>
          }
        />
        {/*  <Route path="contents/*" element={<ContentsPage />} /> */}
        <Route path="user/*" element={<UserPage />} />

        <Route
          path="agency-management/*"
          element={
            <SuspensedView>
              <AgencyManagementActive />
            </SuspensedView>
          }
        />

        <Route
          path="user-management/*"
          element={
            <SuspensedView>
              <UserManagementModule />
            </SuspensedView>
          }
        />

        <Route
          path="product-management/*"
          element={
            <SuspensedView>
              <ProductPage />
            </SuspensedView>
          }
        />

        <Route
          path="purchase-management/*"
          element={
            <SuspensedView>
              <PurchaseHistoryPage />
            </SuspensedView>
          }
        />

        <Route
          path="ab-testing"
          element={
            <SuspensedView>
              <ExperimentSegment />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/agency-management/active" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
      "1.0": baseColor,
    },
    barThickness: 3,
    shadowBlur: 3,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
