import {
  KTCard,
  KTIcon,
  OPTION_KEYS_HEADER,
  PERMISSIONS_MAP,
  hasPermission,
} from "_metronic/helpers";
import TableSummary from "app/_components/TableSummary";
import TableWrapper from "app/_components/coreListData";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "app/_components/coreListData/core/QueryResponseProvider";
import { Table } from "app/_components/table/Table";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { getPaginationPosts } from "./core/_requests";
import { usersColumns } from "./table/_columns";
import { useAuth } from "app/_modules/auth";
import { UsersListHeader } from "app/_components/coreListData/header/ListHeader";

const PurchaseHistoryList = () => {
  const users = useQueryResponseData();
  const data: any = useMemo(() => users, [users]);

  const intl = useIntl();

  const res: any = useQueryResponse();
  const pag = useQueryResponsePagination();

  const { allPermission, myPermission } = useAuth();

  const [row, setRow] = useState<any>(null);
  const [isOpenModalLike, setIsOpenModalLike] = useState(false);
  const [type, setType] = useState("");
  const articleType = res?.response?.articleType;

  let cardList = [
    {
      title: intl.formatMessage({
        id: "PURCHASE",
      }),
      value: pag?.total,
    },
  ];

  let permissionColumns = useMemo(
    () => usersColumns(true),
    [allPermission, myPermission]
  );

  return (
    <div>
      {!data ? null : (
        <UsersListHeader optionsHeader={OPTION_KEYS_HEADER.PURCHASE_HISTORY} />
      )}
      <KTCard>
        <div className="d-flex align-items-center justify-content-between">
          <TableSummary cardList={cardList} />
        </div>
        <Table
          // defineColumns={
          //   hasPermissionUpdate
          //     ? permissionColumns
          //     : permissionColumns.filter((i: any) => i.id !== "action")
          // }
          defineColumns={permissionColumns}
          data={data}
          useQueryResponsePagination={useQueryResponsePagination}
          useQueryResponseLoading={useQueryResponseLoading}
          useQueryRequest={useQueryRequest}
          // className="frenzy"
        />
      </KTCard>
    </div>
  );
};

const UsersListWrapper = () => (
  <TableWrapper module="post" getPanigation={getPaginationPosts}>
    <PurchaseHistoryList />
  </TableWrapper>
);

export default UsersListWrapper;
